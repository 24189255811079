// Ensure all imports are at the top of your file
import React, { useEffect, useState } from "react";
import styles from "./payments.module.css";
import { GetDataPayments } from "./getDataAccount";
import { NavLink } from "react-router-dom";
import { generarStatus } from "./pdfGenerator";
import Loading from "./Loading";

const Payments = (data) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);

  useEffect(() => {
    GetDataPayments(setUser);
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return "Fecha no disponible";
    const date = timestamp.toDate();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses empiezan desde 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async () => {
    setLoading(true); // Simula un delay para mostrar la animación de carga
    generarStatus(user, data);
    setLoading(false);
  };

  return (
    <section className={styles.mainContent}>
      <div className={styles.data_payments}>
        {user.length > 0 ? (
          user.map((payment, index) => (
            <div key={index} className={styles.dataItem}>
              <label className={styles.label}>Folio:</label>
              <h2 className={styles.info}>{payment?.folio || "Folio no disponible"}</h2>
              <label className={styles.label}>Contrato:</label>
              <h2 className={styles.info}>{payment?.id || "Contrato no disponible"}</h2>
              <label className={styles.label}>Nombre:</label>
              <h2 className={styles.info}>{payment?.name || "Nombre no disponible"}</h2>
              <label className={styles.label}>Apellido:</label>
              <h2 className={styles.info}>{payment?.lastName || "Nombre no disponible"}</h2>
              <label className={styles.label}>Monto:</label>
              <h2 className={styles.info}>{payment?.payment || "Monto no disponible"}</h2>
              <label className={styles.label}>Fecha:</label>
              <h2 className={styles.info}>{payment?.date ? formatDate(payment.date) : "Fecha no disponible"}</h2>
              <label className={styles.label}>Recibió:</label>
              <h2 className={styles.info}>{payment?.received || "Recibió no disponible"}</h2>
              {payment.payment_id && (
                <>
                  <label className={styles.label}>Identificador:</label>
                  <h2 className={styles.info}>{payment?.payment_id || "Recibió no disponible"}</h2>
                </>
              )}
            </div>
          ))
        ) : (
          <p>No hay pagos disponibles</p>
        )}
      </div>

      <div className={styles.menu}>
        {/*<div className={styles.linksContent}>
          <a href="#" className={styles.links}>Contrato de membresía</a>
          <a href="#" className={styles.links}>¿Cómo solicito mi servicio?</a>
          <a href="#" className={styles.links}>¿Qué hacer en caso del fallecimiento de un familiar?</a>
        </div>*/}
        <div className={styles.btnContent}>
          <button className={styles.btn} onClick={handleSubmit}>
            {loading ? <Loading /> : "Estado de Cuenta"}
          </button>
          <NavLink to="/" className={styles.btn}>Regresar</NavLink>
        </div>
      </div>
    </section>
  );
};

// Ensure the export is at the bottom of your file
export default Payments;
