import React from "react";
import logo from "./assets/logoNegativo.webp"
import styles from './nav.module.css'
//import { styledNav } from "./nav";

const Header = () => {
    return (
        <header>
            <nav className={styles.nav}>
                <a href="/"><img src={logo} alt="Logo empresa"/></a>
                <h1>Bienvenido</h1>
            </nav>
        </header>
        
    );
}

export default Header;