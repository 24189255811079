import React, { useState, useEffect } from "react";
import styles from "./paymentSuccess.module.css";
import { useLocation, NavLink } from "react-router-dom";
import { queryRecibo } from "./getDataAccount";

export const PaymentSuccess = ({ user }) => {
    const location = useLocation();
    const [paymentResponse, setPaymentResponse] = useState(null);

    useEffect(() => {
        // Obtener los parámetros de la URL
        const query = new URLSearchParams(location.search);
        const status = query.get('status');
        const payment_id = query.get('payment_id');
        const amount = query.get('amount');
        const date_approved = query.get('date_approved');
        const payment_type = query.get('payment_type');

        // Crear un objeto con la respuesta
        const response = { status, payment_id, amount, date_approved, payment_type };

        // Verificar y manejar el estado del pago
        if (status === 'approved') {
            setPaymentResponse(response);
        } else {
            console.error('Pago no aprobado:', status);
        }
    }, [location]);

    const handlePrint = () => {
        queryRecibo(user.id, paymentResponse.payment_id);
    }


    return (
        <section className={styles.mainContent}>
            <div className={styles.data}>
                <h1>Información de pago</h1>
                <div className={styles.dataItem}>
                    <label className={styles.label}>Contrato:</label>
                    <h2 className={styles.info}>{user?.id || "Folio no disponible"}</h2>
                </div>
                <div className={styles.dataItem}>
                    <label className={styles.label}>Nombre:</label>
                    <h2 className={styles.info}>{user?.name || "Nombre no disponible"}</h2>
                </div>
                <div className={styles.dataItem}>
                    <label className={styles.label}>Apellido:</label>
                    <h2 className={styles.info}>{user?.lastName || "Apellido no disponible"}</h2>
                </div>
                <div className={styles.dataItem}>
                    <label className={styles.label}>Monto:</label>
                    <h2 className={styles.info}>$330 MXN </h2>
                </div>
                <div className={styles.dataItem}>
                    <label className={styles.label}>Concepto:</label>
                    <h2 className={styles.info}>Pago por internet</h2>
                </div>
                <div className={styles.dataItem}>
                    <label className={styles.label}>Medio de pago:</label>
                    <h2 className={styles.info}>Mercado Pago</h2>
                </div>
                <div className={styles.dataItem}>
                    <label className={styles.label}>Identificador de pago:</label>
                    <h2 className={styles.info}>{paymentResponse?.payment_id || 'No disponible'}</h2>
                </div>
            </div>
            <div className={styles.btnContent}>
                <NavLink to="/" className={styles.btn}>Inicio</NavLink>
                <button className={styles.btn} onClick={handlePrint}>Descargar recibo</button>
            </div>
        </section>
    );
}
